body {
  font-family: 'Open Sans', sans-serif;
}

.has-cursor-pointer {
  cursor: pointer;
}

.hr {
  margin: 0.75rem 0;
}

.is-family-calistoga {
  font-family: 'Calistoga';
}

.paragraph {
  margin-bottom: 0.75rem;
}

.has-text-green {
  color: #1e4132 !important;

  &:hover,
  &:visited,
  &:focus {
    color: #1e4132 !important;
  }
}

.has-background-mustard {
  background-color: #fdbb34;
}

.has-background-green {
  background-color: #1e4132;
}

.has-letter-spacing {
  letter-spacing: 2px;
}

@media screen and (max-width: 768px) {
  .reverse-row-order-mobile {
    flex-direction: column-reverse;
    display: flex;
  }
  .p-0-mobile {
    padding: 0 !important;
  }
  .p-1-mobile {
    padding: 0.75rem !important;
  }
  .p-2-mobile {
    padding: 1.25rem !important;
  }

  .mr-0-mobile {
    margin-right: 0 !important;
  }
  .mb-1-mobile {
    margin-bottom: 0.75rem !important;
  }
}

.reverse-row-order {
  flex-direction: column-reverse;
  display: flex;
}

.m-0 {
  margin: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.75rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.75rem !important;
}

.mb-2 {
  margin-bottom: 1.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.p-1 {
  padding: 0.75rem !important;
}
.pt-1 {
  padding-top: 0.75rem !important;
}

.p-2 {
  padding: 1.25rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.has-default-background-image {
  position: relative;
  &::before {
    content: '';
    background-image: url(/assets/img/chat.svg);
    background-position: 0% 0%;
    background-repeat: repeat;
    background-size: 100%;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
  }
}

.has-default-background-image-mobile {
  position: relative;
  @media screen and (max-width: 768px) {
    &::before {
      content: '';
      background-image: url(/assets/img/chat.svg);
      background-position: 0% 0%;
      background-repeat: repeat;
      background-size: 100%;
      opacity: 0.2;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 0;
    }
  }
}

.is-container {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
}

/* react-textarea-autocomplete */
.rta__item--selected {
  background-color: #f5f5f5;
}

/* hide tooltips on mobile */
[data-tooltip]:not(.tooltip-is-mobile)::before {
  @media screen and (max-width: 768px) {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
